import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const Container = styled.div`
    #show-four-items [name="faq"]:not(:nth-of-type(-n + 4)) {
        display: none;
    }
    #show-all [name="faq"] {
        display: block;
    }

    #faq-show {
        display: block;
        max-height: 100vh;
        min-height: 8rem;
        [name="faq-description"] {
            display: block;
            max-height: 100vh;
        }
        transition: all 0.3s ease-in-out;
    }

    #arrow-icon path {
        transition: transform 0.3s ease-in-out;
        margin: 0 auto;
        width: fit-content;
    }

    #arrow-icon {
        display: flex;
        align-items: flex-start;
        width: fit-content;
        transition: all 300ms linear;
        transform: rotate(0);
    }

    #faq-show #arrow-icon {
        transform: rotate(180deg);

        path {
            stroke: #c4b180;
        }
    }
    #faq-show [name="faq-title"] {
        color: #c4b180;
    }
`;

export const FAQItem = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 1.5rem 16rem;
    margin: 0 auto;
    border: 0.01rem solid #3a1a3c;
    max-height: 8rem;
    min-height: 5.4rem;
    transition: 0.3s;

    [name="faq-description"] {
        display: none;
        max-height: 0;
    }

    &:hover {
        cursor: pointer;
        background: rgba(196, 177, 128, 0.1);
        border: 0.01rem solid #c4b180;

        [name="faq-title"] {
            color: #c4b180;
        }
    }
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        max-height: 15rem;
    }
`;

export const ContentContainer = styled.div`
    margin: 5rem -15.9rem;
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin: 2rem -15.9rem;
    }
`;

export const FAQTitle = styled.h4`
    font-size: 1.75rem;
    margin: 0;
    line-height: 2.25rem;
    color: var(--main-font-color);
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-right: 1.5rem;
        width: fit-content;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 1.25rem;
        line-height: 1.875rem;
        margin-right: 0.8rem;
    }
`;

export const ButtonContainer = styled.div`
    max-width: 19.125rem;
    margin: 0 auto;
    .button {
        justify-content: center;
        height: 3.5rem;
    }
`;

export const FAQTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        align-items: flex-start;
        svg {
            margin-top: 0.75rem;
        }
    }
`;
