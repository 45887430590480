import React, { useLayoutEffect } from "react";
import Button from "@common/Button/Button";
import { Title } from "../CommonStyles/CommonStyles.style";
import {
    ButtonContainer,
    Container,
    ContentContainer,
    FAQItem,
    FAQTitle,
    FAQTitleContainer,
} from "./FAQSection.style";
import { Description } from "../../../../styles/CommonStyles";

const FAQSection = ({ items }) => {
    
    useLayoutEffect(() => {
        if (document) {
          const el = document.getElementById("show-btn")
          if (el) {
            el.addEventListener("click", () => {
              const containerShowFourItems =
                document.getElementById("show-four-items")
              const containerShowAll = document.getElementById("show-all")
              if (containerShowAll) {
                el.textContent = "Show"
                containerShowAll.id = "show-four-items"
              }
              if (containerShowFourItems) {
                el.textContent = "Less"
                containerShowFourItems.id = "show-all"
              }
            })
          }
          const listItems = document.querySelectorAll('[name="faq"]')
          if (listItems) {
            listItems.forEach(item => {
              item.addEventListener("click", () => {
                const isIdSet = item.getAttribute("id") === "faq-show"
                if (isIdSet) {
                  item.removeAttribute("id")
                } else {
                  item.setAttribute("id", "faq-show")
                }
              })
            })
          }
        }
      }, [document]);

    return (
    <Container>
        <Title>FAQ</Title>
        <ContentContainer id="show-four-items">
            {items.map(item => (
                <FAQItem name="faq">
                    <FAQTitleContainer>
                        <FAQTitle name="faq-title">{item.title}</FAQTitle>
                        <svg
                            width="22"
                            height="13"
                            viewBox="0 0 22 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            id="arrow-icon"
                        >
                            <path
                                d="M1 1L11 11L21 1"
                                stroke="white"
                                strokeWidth="2"
                            />
                        </svg>
                    </FAQTitleContainer>
                    <Description name="faq-description">
                        {item.text}
                    </Description>
                </FAQItem>
            ))}
        </ContentContainer>
        {items.length > 4 && (
            <ButtonContainer>
                <Button id="show-btn" text="More" />
            </ButtonContainer>
        )}
    </Container>
    )
};

export default FAQSection;
